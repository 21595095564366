import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Fees" />

    <div>
      <h1>
        Fe<span className="text-blue-600">es</span>
      </h1>

      <p>
        Individual session .. 1 hour…£40 self funding, £45 external funding.
        (This is due to the extra admin needed, invoices etc).
      </p>
      <p>
        Student Counsellor session… 1 hour… £30 self funding, £35 external
        funding. (This is due to the extra admin needed, invoices etc).
      </p>
      <p>
        Couples session… 1 hour…£50 self funding, £55 external funding. (This is
        due to the extra admin needed, invoices etc).
      </p>
      <p>EAPs £40-£60</p>
      <p>
        Supervision session… 1.5 hours…£60.00. £65.00 external funding, (This is
        due to the extra admin needed, invoices etc).
      </p>
    </div>
  </Layout>
)

export default IndexPage
